@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    html{
        font-family: glancyr,sans-serif;
    }
}

@font-face {
    font-family: 'glancyr';
    src: url('./Assets/fonts/Glancyr\ Variable-VF.ttf') format('truetype');
  }