/* Styles for headers */
.h5{
    font-size: 3.2em;
}
.h1 {
    font-size: 2.9em;
    font-weight: 700;
}
.h2{
    font-size: 2.6em;
    font-weight: 700;
}
.h3{
    font-size: 2em;
    font-weight: 300;
}
.h4{
    font-size:1.4em;
    font-weight:500;
}
.p1{
    font-size: .9em;
    font-weight: 400;
}
.p2{
    font-size: 1em;
    font-weight: 600;
}
.p3{
    font-size: 0.8em;
    font-weight: 500;
}
.p4{
    font-size: 0.6em;
    font-weight:600;
}
/* .noBackground{
    background-image: url('../Images/spacebg.png');
    background-size: contain;
    background-repeat: no-repeat;
} */
.home{
    background-image: url('https://res.cloudinary.com/dewopisee/image/upload/v1683030288/BG-2_n90l6s.png');
    background-size: cover;
}
.business{
    background-image: url('https://res.cloudinary.com/dewopisee/image/upload/v1683030288/BG-2_n90l6s.png');
    background-size: cover;
}
/* .businessBackground{
    background-image: url('../Images/store-front2.png');
    background-size: contain;
    background-repeat: no-repeat;
} */
@media only screen and (max-width: 720px) {
    .home{
        background-image: url('https://res.cloudinary.com/dewopisee/image/upload/v1683032536/BG-6_ig1d9y.png');
        background-size: cover;

    }
}
@media only screen and (max-width: 820px) {
    /* .h1 {
        font-size: 38px;
        font-weight: 700;
    }
    .h2{
        font-size: 42px;
        font-weight: 700;
    }
    .h3{
        font-size: 30px;
        font-weight: 300;
    }
    .h4{
        font-size:18px;
        font-weight:500;
    } */
    .noBackground{
        background-image: url();
    }
    
    .businessBackground{
        background-image: url();
        
    }
}